import { useQuery } from "@tanstack/react-query";

const base_url =
  "https://nominatim.openstreetmap.org";

function useGeocode({ query }) {
  const url = `${base_url}/search.php?format=jsonv2&q=${ query }`;
  const resp = useQuery({
    queryKey: ["geocode", "", { url }],
    enabled: !!query,
  });
  return resp;
}

function useReverseGeocode({ latitude, longitude }) {
  const url = `${base_url}/reverse.php?format=jsonv2&lat=${latitude}&lon=${longitude}`
  const resp = useQuery({
    queryKey: ["", "reverseGeocode", { url }],
    staleTime: 1000 * 60 * 60 * 24 * 14,
    enabled: latitude !== null,
  });
  return resp;
}

export { useGeocode, useReverseGeocode };
