import { useForecast as useNoaaForecast } from "./noaa";
import { useForecast as usePirateForecast } from "./pirateweather";
import { useForecast as useOWMForecast } from "./openweathermap";

export default function useForecast({source, latLong}) {
  return {
    "noaa": useNoaaForecast({latLong, enabled: source == "noaa"}),
    "pirateweather": usePirateForecast({latLong, enabled: source == "pirateweather"}),
    "openweathermap": useOWMForecast({latLong, enabled: source == "openweathermap"}),
  }
}

export {
  useForecast,
}
