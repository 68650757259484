import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useGeocode } from "../../lib/geocode";
import useGeolocation from "../../lib/geolocate";
import useDebounce from "../../lib/useDebounce";
import { haversineDistance } from "../../lib/utils";

export default function GeocodeAutocomplete(props) {
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const location = useGeolocation({enableHighAccuracy: true});
  const query = useDebounce(inputValue, 250);
  const geocode = useGeocode({ query: query });
  const position = [location.latitude, location.longitude];
  const options = React.useMemo(() => {
    if ( ! geocode.isSuccess ) return [];
    return geocode.data.sort((a, b) => {
      const da = haversineDistance([a.lat, a.lon], position);
      const db = haversineDistance([b.lat, b.lon], position);
      return (da > db)? 1 : -1;
    })},
    [geocode.data, location.data]
  );
  const setName = props.setName;
  const setGeo = props.setGeo;

  return (
    <Autocomplete
      style={{ marginTop: "1em", marginBottom: "1em" }}
      id="geocode-autocomplete"
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      filterOptions={(x) => x}
      getOptionLabel={(option) => {
        if (option == []) return "";
        return typeof option === "string" ? option : option.display_name;
      }}
      onChange={(_, newValue) => {
        if ( newValue ) {
          setName(newValue.name);
          setGeo(`${newValue.lat},${newValue.lon}`);
          setInputValue("");
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Search locations" fullWidth />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.place_id}>
            <Typography>{option.display_name}</Typography>
          </li>
        );
      }}
      isOptionEqualToValue={(option, value) => {
        return option.place_id === value.place_id;
      }}
    />
  );
}
