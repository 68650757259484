import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { Draggable } from "react-beautiful-dnd";
import { DragIndicator } from "@mui/icons-material";

import LocationEditButton from "../LocationEditButton";


export function Location(props) {
  const theme = useTheme();
  return (
    <Draggable
      draggableId={props.geo}
      index={props.index}
      isDragDisabled={!props.editing}
    >
      {(provided, snapshot) => (
        <ListItem
          disablePadding
          key={props.geo}
          {...provided.draggableProps}
          ref={provided.innerRef}
          sx={
            snapshot.isDragging
              ? { backgroundColor: theme.palette.background.paper }
              : {}
          }
        >
          <div
            {...provided.dragHandleProps}
          >
          <DragIndicator
            style={{
              color: theme.palette.text.disabled,
              display: props.editing? "inline-block" : "none",
            }}
          />
          </div>
          <ListItemButton
            component={Link}
            to={props.dragging ? "" : `/forecast/${props.geo}`}
          >
            <ListItemText>{props.name}</ListItemText>
          </ListItemButton>
          <ListItemSecondaryAction>
            <LocationEditButton
              editing={props.editing}
              dragging={props.dragging}
              dispatch={props.dispatch}
              defaultName={props.name}
              defaultGeo={props.geo}
            />
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </Draggable>
  );
}
