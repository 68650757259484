import React, { lazy, Suspense } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import ReactJson from 'react-json-view';

import { useForecast } from "../../lib/forecast";
import { useLocations } from "../../lib/locations";

import TabPanel from "../TabPanel";
import CurrentObservations from "../CurrentObservations";
import DailyView from "../DailyView";
import SourceSwitcher from "../SourceSwitcher";

const MapView = lazy(() => import("../MapView"));

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  timestamp: {
    textAlign: "end",
    marginLeft: "auto",
    marginTop: "0.5em",
    color: theme.palette.text.hint,
  },
}));

const forecast_sources = ["noaa", "openweathermap", "pirateweather"];

export default function LocationDetail() {
  const { geo } = useParams();
  const { getAltitude, getSource } = useLocations();
  const [searchParams, _] = useSearchParams();
  const [source, setSource] = React.useState(getSource(geo) || "noaa");
  const forecasts = useForecast({ latLong: geo, source });
  let forecast = forecasts[source];
  // FIXME move this logic to SourceSwitcher. Don't even visit sources with a 404 response again
  if ( !!forecast && forecast.isError ) {
    forecast_sources.every(name => {
      if ( name == source ) return true;
      setSource(name);
    });
  };
  const altitude = Object.fromEntries(searchParams).altitude || getAltitude(geo);
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(0);
  const data = forecast.data;
  const tabChange = (_, newIndex) => {
    setTabIndex(newIndex);
  };
  localStorage.setItem("lastGeo", geo);
  let currently = forecast.data?.currently;
  let station = forecast.data?.station;
  let header = "Current Observations";
  if ( altitude != null ) header += ` @ ~${(altitude * 3.28084).toFixed(0)}ft`;
  return (
    <div style={{ width: "100%" }}>
      <Typography
      >
        {header}
      </Typography>
      <SourceSwitcher source={source} setSource={setSource} />
      <CurrentObservations currently={currently} station={station} />
      <Tabs value={tabIndex} onChange={tabChange}>
        <Tab label="Forecast" id="tab-0" />
        <Tab label="Map" id="tab-1" />
        <Tab
          label="𝜋"
          id="tab-2"
          sx={{marginLeft: "auto", color: "#202020" }}
        />
      </Tabs>
      <TabPanel current={tabIndex} index={0}>
        <DailyView forecast={forecast} />
      </TabPanel>
      <TabPanel current={tabIndex} index={1}>
        <Paper className={classes.paper}>
          <Suspense fallback="">
            <MapView
              gridpoint={data?.gridpoint}
              showing={tabIndex === 1}
              station={station}
            />
          </Suspense>
        </Paper>
      </TabPanel>
      <TabPanel current={tabIndex} index={2}>
        <Paper className={classes.paper} sx={{textAlign: "start"}}>
          <ReactJson
            src={forecast.data}
            theme="summerfruit"
            collapsed={1}
            collapseStringsAfterLength="50"
            enableClipboard={false}
            displayDataTypes={false}
            sortKeys={true}
            name={false}
          />
        </Paper>
      </TabPanel>

    </div>
  );
}
