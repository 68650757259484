import React, { useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import { LocationContext } from "../../lib/locations";

export default function ImportLocationsDialog(props) {
  const { validateLocations } = useContext(LocationContext);
  const [text, setText] = React.useState("");
  const [textValid, setTextValid] = React.useState(false);
  const parseNoaaAppBackup = React.useCallback(
    (text) => {
      const result = [];
      let i = 0;
      let loc = {};
      text.split("\n").forEach((line) => {
        if (i === 0) loc.geo = line;
        else if (i === 1) loc.geo = `${loc.geo},${line}`;
        else if (i === 2) loc.name = line;
        else if (i === 3) {
          if (validateLocations([loc])) result.push(loc);
          i = -1;
          loc = {};
        }
        i++;
      });
      return result;
    },
    [validateLocations]
  );
  const validateText = React.useCallback(
    (text) => {
      let obj = [];
      try {
        obj = JSON.parse(text);
      } catch (error) {
        obj = parseNoaaAppBackup(text);
      }
      return validateLocations(obj);
    },
    [validateLocations, parseNoaaAppBackup]
  );
  const textChanged = (value) => {
    setTextValid(validateText(text));
    setText(value);
  };
  const obj = React.useMemo(() => {
    let obj;
    try {
      obj = JSON.parse(text);
    } catch (error) {
      obj = parseNoaaAppBackup(text);
    }
    return obj;
  }, [text, parseNoaaAppBackup]);
  React.useEffect(() => {
    if (props.open) {
      setTextValid(validateText(text));
    }
  }, [props.open, text, validateText]);
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Import Locations</DialogTitle>
      <DialogContent>
        <Typography>Note: This will replace all existing locations!</Typography>
        <TextField
          multiline={true}
          minRows={10}
          value={text}
          error={!textValid}
          margin="dense"
          id="name"
          label="JSON Data"
          onChange={(evt) => {
            textChanged(evt.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!textValid}
          onClick={() => {
            props.handleClose({
              type: "replaceAll",
              payload: { locations: obj },
            });
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
