import React from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import ErrorFallback from "./components/ErrorFallback";
import AppBar from "./components/AppBar";

import "./App.css";

function App(props) {
  const lastGeo = localStorage.getItem("lastGeo");
  const location = useLocation();
  if (lastGeo && location.pathname.endsWith("/")) {
    return <Navigate replace to={`/forecast/${lastGeo}`} />;
  }
  // console.log("App", location, lastGeo);
  return (
    <div className="App">
      <AppBar setDrawerOpen={props.setDrawerOpen}></AppBar>
      <div className="main">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Outlet />
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default App;
