import axios from "axios";
import { useMemo } from "react";
import { useQuery, useQueries } from "@tanstack/react-query";
import {
  addHours,
  startOfDay,
  isSameDay,
  isBefore,
  addDays,
  format,
} from "date-fns";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import * as duration from "duration-fns";
import { getSunrise, getSunset } from "sunrise-sunset-js";
import Quant from "js-quantities";

import { haversineDistance } from "./utils";

const noaaBaseUrl = "https://api.weather.gov";

function useForecast({ latLong, enabled }) {
  const [lat, lng] = latLong.split(",");
  const {
    data,
    isError,
    isSuccess,
    error,
    isLoading,
    isInitialLoading,
    dataUpdatedAt,
  } = useNoaaForecast({
    latLong,
    enabled,
  });
  const observations = useObservations({
    latLong,
    stations: data?.stations,
    enabled,
    timezone: data?.timeZone,
  });
  const backfilled = useMemo(() => {
    const copy = JSON.parse(JSON.stringify(data));
    if (!isError && !isLoading) return backfillDaily(copy, lat, lng);
    return copy;
  }, [dataUpdatedAt, isLoading]);
  const result = {
    data: {
      ...data,
      ...backfilled,
      ...observations?.data || {}
    },
    isError,
    isSuccess,
    error,
    isLoading,
    isInitialLoading,
    dataUpdatedAt,
  };
  return result;
}

function usePoint({ latLong, enabled }) {
  const url = `${noaaBaseUrl}/points/${latLong}`;
  const resp = useQuery({
    queryKey: ["noaa", "point", { url }],
    staleTime: 1000 * 60 * 60 * 24 * 7,
    retry:(_, error) => {return error?.response.status != 404},
    enabled,
  });
  return resp;
}

function useNoaaForecast({ latLong, enabled }) {
  const pointResp = usePoint({ latLong, enabled });
  const subqueryConfigs = useMemo(() => {
    return {
      forecast: {
        staleTime: 1000 * 60 * 60,
        select: (data) => {
          return {
            timeZone: pointResp.data.properties.timeZone,
            daily: {data: transformDaily(data, pointResp.data.geometry.coordinates)},
          };
        },
      },
      forecastGridData: {
        staleTime: 1000 * 60 * 15,
        select: (data) => {
          return {
            gridpoint: {
              type: "Feature",
              geometry: data.geometry,
            },
            hourly: {data: gridToParts(data)},
          };
        },
      },
      observationStations: {
        staleTime: 1000 * 60 * 60 * 24 * 7,
        cacheTime: 1000 * 60 * 60 * 24 * 7,
        select: (data) => {
          return {
            stations: [...data.features],
          };
        },
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };
  }, [
    pointResp.data?.geometry.coordinates,
    pointResp.data?.properties.timeZone,
  ]);
  const subqueryNames = Object.keys(subqueryConfigs);
  const subqueries = {};
  const subqueryList = useQueries({
    queries: subqueryNames.map((name) => {
      const subquery = {
        ...subqueryConfigs[name],
        queryKey: ["noaa", name, { url: pointResp.data?.properties[name] }],
        enabled: enabled && pointResp.isSuccess,
      };
      subqueries[name] = subquery;
      return subquery;
    }),
  });
  subqueryList.forEach((item, index) => {
    subqueries[subqueryNames[index]] = item;
  });
  const newData = useMemo(() => {
    const e = subqueryList.map((item) => {
      return Object.entries(item.data || {});
    });
    return Object.fromEntries(e.flat());
  }, [subqueryList]);
  const allQueries = [pointResp].concat(Object.values(subqueries));
  const result = {
    isError: allQueries.some((item) => item.isError),
    isSuccess: allQueries.every((item) => item.isSuccess),
    error: allQueries.map((item) => item.error),
    isLoading: allQueries.some((item) => item.isLoading),
    isInitialLoading: allQueries.some((item) => item.isInitialLoading),
    dataUpdatedAt: Math.max(
      ...allQueries
        .filter((item) => item !== null)
        .map((item) => item.dataUpdatedAt)
    ),
    data: null,
  };
  if (allQueries.map((item) => item.isSuccess)) result.data = newData;
  // console.log("noaa", result);
  return result;
}

function transformDaily(raw) {
  const tluser = {}; // 'result' but backwards aren't i so clever 🥴
  // drop days in the past
  const today = new Date();
      today.setHours(0, 0, 0, 0);
  let periods = raw.properties.periods.filter(
    period => ! isBefore(Date.parse(period.startTime), today)
  );
  periods.reverse().forEach((period) => {
    const dayStart = startOfDay(Date.parse(period.startTime)).getTime();
    const part = getForecastPart(period);
    if (part && tluser[dayStart] === undefined) tluser[dayStart] = {};
    tluser[dayStart] = { ...tluser[dayStart], ...part };
    if (Object.keys(tluser[dayStart]).length === 0) delete tluser[dayStart];
  });
  // Because we know this is backwards, re-sort it by its keys
  // const result = Object.fromEntries(Object.entries(tluser).sort());
  const result = Object.values(tluser).sort((a, b) => {
    if ( a.time < b.time ) return -1;
    if ( a.time > b.time ) return 1;
    return 0;
  });
  return Object.values(result);
}

function numbers(list) {
  return list.filter((i) => i !== undefined && !Number.isNaN(i));
}

function backfillDaily(data, lat, lng) {
  if (!Object.keys(data).length) return;
  if (data.backfilled === true) return;
  // console.log("backfillDaily", data);
  Object.values(data.daily.data).forEach((dayObj) => {
    const dayDate = utcToZonedTime(dayObj.time * 1000, data.timeZone);
    const temps = Object.values(data.hourly.data)
      .filter(hour => isSameDay(new Date(Number(hour.time) * 1000), dayDate))
      .map(hour => hour.temperature);
    dayObj.temperatureHigh = Math.max(...numbers(temps));
    dayObj.temperatureLow = Math.min(...numbers(temps));
  });
  data.daily.data.forEach(day => {
    const dayDate = new Date(day.time * 1000);
    const sunrise = getSunrise(lat, lng, dayDate);
    var sunset = getSunset(lat, lng, dayDate);
    if (isBefore(sunset, sunrise)) {
      sunset = getSunset(lat, lng, addDays(dayDate, 1));
    }
    day.sunriseTime = sunrise.getTime() / 1000;
    day.sunsetTime = sunset.getTime() / 1000;
    const hourIndices = Object.keys(data.hourly.data);
    const hoursInDay = hourIndices.filter(i => {
      const hourDate = utcToZonedTime(Number(data.hourly.data[i].time) * 1000, data.timeZone);
      return isSameDay(dayDate, hourDate);
    });
    if (!hoursInDay.length) console.log(dayDate, hoursInDay);
    day.precipProbability = Math.max(
      ...hoursInDay.map((hour) => data.hourly.data[hour].precipProbability)
    );
    const hoursSpan =
      (hoursInDay[hoursInDay.length - 1] - hoursInDay[0]) / 3600 + 1;
    day.humidity =
      hoursInDay
        .map((hour) => data.hourly.data[hour].humidity)
        .reduce((total, value) => total + value, 0) / hoursSpan;
    day.windBearing =
      hoursInDay
        .map((hour) => data.hourly.data[hour].windBearing)
        .reduce((total, value) => total + value, 0) / hoursSpan;
  });
  data.backfilled = true;
  return data;
}

function useObservations({latLong, stations, enabled, timezone}) {
  // console.log("useObservations", latLong, stations);
  const [lat, lng] = latLong.split(",").map(n => Number(n));
  const distanceToGeo = (point) => {
    return Math.round(haversineDistance([lat, lng], point));
  };
  if (stations) stations.sort((a, b) => { 
    const pointA = a.geometry.coordinates.toReversed();
    const pointB = b.geometry.coordinates.toReversed();
    const distA = distanceToGeo(pointA);
    const distB = distanceToGeo(pointB);
    if ( distA === distB ) return 0;
    if ( distA < distB ) return 1;
    return -1;
  });
  if (stations) stations.reverse();
  const queryFn = async ({ queryKey }) => {
    return axios.get(queryKey[2].url, { timeout: 5000 }).then((resp) => {
      return resp.data;
    });
  };
  const options = {
    queryFn,
    keepPreviousData: false,
    select: (data) => {
      const samples = data.features.filter(
        (item) => item.properties.temperature.value !== null
      );
      samples.sort(
        (a, b) =>
          Date.parse(b.properties.timestamp) -
          Date.parse(a.properties.timestamp)
      );
      if (!samples.length) return {};
      const station = {
        code: samples[0].properties.station.split("/").slice(-1)[0],
        coords: [...samples[0].geometry.coordinates],
      };
      station.coords.reverse();
      station.distance = distanceToGeo(station.coords);
      return {
        currently: getForecastPart(samples[0].properties),
        station,
      };
    },
  };
  const getUrl = (i) => {
    if (stations === undefined) return { url: "" };
    let startDate = addHours(new Date(), -3);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    let minutes = startDate.getMinutes();
    // snap to five-minute intervals to keep the cache slightly warmer when
    // the user is going back and forth between a few locations in a short 
    // period of time
    startDate.setMinutes(minutes - minutes % 5);
    let start = formatInTimeZone(startDate, timezone, "yyyy-MM-dd'T'HH:mm:ssxxxxx");
    return { url: `${stations[i].id}/observations?start=${start}` };
  };
  const queryFailed = (query) => {
    if ( query.isError ) return true;
    if ( query.isSuccess && !query.data?.currently ) return true;
    return false;
  }
  const queries = [];
  const queryKey = url => { return ["noaa", "observations", url]};
  queries.push(
    useQuery({
      queryKey: queryKey(getUrl(0)),
      enabled: enabled && !! timezone && Boolean(getUrl(0) && stations && stations.length),
      ...options,
    })
  );
  queries.push(
    useQuery({
      queryKey: queryKey(getUrl(1)),
      enabled: enabled && queryFailed(queries[0]),
      ...options,
    })
  );
  queries.push(
    useQuery({
      queryKey: queryKey(getUrl(2)),
      enabled: enabled && queries[1].isError,
      enabled: enabled && queryFailed(queries[1]),
      ...options,
    })
  );
  queries.push(
    useQuery({
      queryKey: queryKey(getUrl(3)),
      enabled: enabled && queryFailed(queries[2]),
      ...options,
    })
  );
  queries.push(
    useQuery({
      queryKey: queryKey(getUrl(4)),
      enabled: enabled && queries[3].isError,
      ...options,
    })
  );
  queries.push(
    useQuery({
      queryKey: queryKey(getUrl(5)),
      enabled: enabled && queries[4].isError,
      queryFn,
    })
  );

  for (let i = 0; i < queries.length; i++) {
    const query = queries[i];
    if (stations && query.isSuccess && !! query.data?.currently ) {
      return query;
    }
  }
}

function getForecastPart(raw) {
  if (raw === undefined) return null;
  const part = {};
  part.icon = raw.icon ? parseIconUrl(raw.icon) : null;
  part.summary = raw.textDescription || raw.shortForecast;
  part.detail = raw.detailedForecast;
  part.time = Date.parse(raw.timestamp || raw.startTime) / 1000;
  Object.keys(fieldMap).forEach((key) => {
    const dest = fieldMap[key];
    const name = dest.name || key;
    const rawData = raw[key];
    if (typeof rawData === "object") {
      // everything but current observations
      const rawValue = rawData.value;
      if (rawValue === null) {
        part[name] = null;
      } else {
        const unit = parseUnit(rawData.unitCode);
        let newValue = dest.unit
          ? Quant(rawValue + " " + unit)
              .to(dest.unit)
              .toPrec(0.01).scalar
          : rawValue;
        if (dest.scale != null) newValue *= dest.scale;
        part[name] = newValue;
      }
    } else {
      // current observations
      if (rawData !== undefined) {
        if (key === "windDirection") {
          //console.log('windDirection', part[key]);
          //part[key] = cardinalDirectionMap[rawData]
        } else if (key === "windSpeed") {
          const rangeRE = /(\d+) to (\d+) (\D+)/;
          const matches = rawData.match(rangeRE);
          if (matches === null) {
            part[name] = Quant(rawData).scalar;
          } else {
            const rawLower = `${matches[1]} ${matches[3]}`;
            const rawUpper = `${matches[2]} ${matches[3]}`;
            part[name] = `${Quant(rawLower).scalar} to ${
              Quant(rawUpper).scalar
            } ${matches[3]}`;
          }
        } else {
          part[name] = rawData;
        }
      }
    }
  });
  return part;
}

function hoursInDuration(isoString) {
  const [startString, duration_] = isoString.split("/");
  const startDate = new Date(startString);
  const hours = duration.toHours(duration.parse(duration_));
  var result = [];
  for (var i = 0; i < hours; i++) {
    result.push(addHours(startDate, i));
  }
  return result;
}

function gridToParts(grid) {
  const measurements = [
    "relativeHumidity",
    "dewpoint",
    "temperature",
    "probabilityOfPrecipitation",
    "quantitativePrecipitation",
    "windSpeed",
    "windGust",
    "windDirection",
  ];
  const parts = {};
  hoursInDuration(grid.properties.validTimes).forEach((date) => {
    const time = date.getTime() / 1000;
    parts[time] = { time: time }; //, startTime: jsonDate };
  });
  measurements.forEach((measurement) => {
    let newName = measurement;
    const dest = fieldMap[measurement]
    if (
      dest !== undefined &&
      dest.name !== undefined
    ) {
      newName = dest.name;
    }
    const uom = parseUnit(grid.properties[measurement].uom);
    //console.log(`${measurement} ${uom}`);
    grid.properties[measurement].values.forEach((valueObj) => {
      var newValue = valueObj.value;
      if (
        dest !== undefined &&
        dest.unit !== undefined
      ) {
        newValue = Quant(newValue + " " + uom)
          .to(dest.unit)
          .toPrec(0.01).scalar;
      }
      if (dest.scale != null) newValue *= dest.scale;
      //if ( uom === 'percent' ) { newValue = newValue / 100 };
      if (uom === "percent") {
        //console.log(measurement, uom, newValue);
      }
      hoursInDuration(valueObj.validTime).forEach((date) => {
        const time = date.getTime() / 1000;
        if (parts[time] === undefined) {
          //console.log(`extra time slot: ${time} ${new Date(time)}`);
        } else {
          parts[time][newName] = newValue;
        }
      });
    });
  });
  return parts;
}

function parseIconUrl(url) {
  const iconMap = [
    //  RE  | day  | night
    [/skc/, "DaySunny", "NightClear"],
    [/hot/, "Hot", "Hot"],
    [/wind/, "DayWindy", "Windy"],
    [/tornado/, "Tornado"],
    [/hurricane/, "Hurricane"],
    [/tropical_storm/, "Hurricane"],
    [/(few|sct)/, "DayCloudy", "NightPartlyCloudy"],
    [/(bkn|ovc)/, "Cloudy"],
    [/sleet/, "DaySleet", "NightSleet"],
    [/rain/, "DayRain", "NightRain"],
    [/fzra/, "DayRainMix", "NightRainMix"],
    [/tsra/, "DayThunderstorm", "NightThunderstorm"],
    [/snow/, "DaySnow", "NightSnow"],
    [/fog/, "DayFog", "NightFog"],
    [/smoke/, "Smoke"],
    [/haze/, "DayHaze", "NightFog"],
  ];
  const base = new RegExp(".*/icons/[^/]+/([^?]*).*");
  let [night_or_day, condition] = url.replace(base, "$1").split("/");
  const matches = iconMap.filter((item) => condition.match(item[0]));
  if (matches.length) {
    if (night_or_day === "day") {
      condition = matches[0][1];
    } else if (night_or_day === "night") {
      condition = matches[0].length > 2 ? matches[0][2] : matches[0][1];
    } else {
      condition = "Alien";
    }
  } else {
    condition = "Alien";
  }
  return "Wi" + condition;
}

function getCardinalDirection(angle) {
  const directions = ["↑", "↗", "→", "↘", "↓", "↙", "←", "↖"];
  return directions[Math.round(angle / 45) % 8];
}

function parseUnit(unit_str) {
  if (unit_str.startsWith("unit:") || unit_str.startsWith("wmoUnit:")) {
    return unit_str
      .split(":")[1]
      .replace("deg", "temp")
      .replace("_", "/")
      .replace("-1", "");
  }
  return unit_str;
}

const fieldMap = {
  apparentTemperature: {
    unit: "tempF",
    name: "temperature",
  },
  temperature: {
    unit: "tempF",
  },
  dewpoint: {
    name: "dewPoint",
    unit: "tempF",
  },
  windSpeed: {
    unit: "mi/h",
  },
  windGust: {
    unit: "mi/h",
  },
  windDirection: {
    name: "windBearing",
  },
  barometricPressure: {
    name: "pressure",
    unit: "mbar",
  },
  // seaLevelPressure
  visibility: {
    unit: "mi",
  },
  // maxTemperatureLast24Hours
  // minTemperatureLast24Hours
  probabilityOfPrecipitation: {
     name: 'precipProbability',
     scale: 1/100,
  },
  // precipitationLastHour
  // precipitationLast3Hours
  // precipitationLast6Hours
  quantitativePrecipitation: {
    name: "precipAccumulation",
    unit: "in",
  },
  relativeHumidity: {
     name: 'humidity',
     scale: 1/100,
  },
  // windChill
  // heatIndex
  // cloudLayers
};

export {
  useForecast,
  usePoint,
  parseIconUrl,
  getCardinalDirection,
};
