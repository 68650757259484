function haversineDistance(a, b) {
  const r = 6371; // radius of Earth in km
  const p = Math.PI / 180; // degrees to radians
  const latRadians = (b[0] - a[0]) * p;
  const lonRadians = (b[1] - a[1]) * p;
  const x = 0.5 - Math.cos(latRadians) / 2 + Math.cos(a[0] * p) * Math.cos(b[0] * p) * (1 - Math.cos(lonRadians)) / 2;
  const dist = 2 * r * Math.asin(Math.sqrt(x)) * 0.6213712;
  return dist;
  }

export { haversineDistance };
