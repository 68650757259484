import Box from "@mui/material/Box";

export default function TabPanel(props) {
  return (
    <Box
      id={props.index}
      hidden={props.current !== props.index}
      role="tabpanel"
      sx={{ "& .MuiPaper-root": { padding: 0 } }}
    >
      {props.children}
    </Box>
  );
}
