import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/material/styles";
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
  Area,
} from "recharts";
import {
  isBefore,
  isWithinInterval,
  isSameDay,
  startOfDay,
  addDays,
} from "date-fns";
import { format } from "date-fns-tz";

const timeFormat = "HH:mm";
const dateFormat = "E " + timeFormat;

const useStyles = makeStyles(() => ({
  chartContainer: {
    fontSize: "small",
    height: (props) => props.height,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    overflowX: (props) =>
      props.day || props.width === "100%" ? "unset" : "scroll",
  },
}));

function formatHour(hour) {
  return {
    Time: hour.time * 1000,
    Temperature: Math.round(hour.temperature),
    Humidity: Math.round(hour.humidity * 100),
    "Precip. Chance": Math.round(hour.precipProbability * 100),
    "Wind Speed": Math.round(hour.windSpeed),
  };
}

function snapSunEvents(hourly, daily) {
  const hours = hourly.map((hour) => new Date(hour.time * 1000));
  const chartInterval = { start: hours[0], end: hours[hours.length - 1] };
  var sunrises = daily.map((day) => new Date(day.sunriseTime * 1000));
  var sunsets = daily.map((day) => new Date(day.sunsetTime * 1000));
  sunrises = sunrises.filter((t) => {
    return isWithinInterval(t, chartInterval);
  });
  sunsets = sunsets.filter((t) => {
    return isWithinInterval(t, chartInterval);
  });
  if (isBefore(chartInterval.start, sunrises[0])) {
    sunsets.unshift(chartInterval.start);
  }
  if (isBefore(sunsets[0], chartInterval.end)) {
    sunrises.push(chartInterval.end);
  }
  var nightCount = sunsets.length;
  const nights = [...Array(nightCount).keys()].map((i) => {
    return [sunsets[i].getTime(), sunrises[i].getTime()];
  });
  /*
  const nightsDbg = [...Array(nightCount).keys()].map((i) => {
    return [sunsets[i], sunrises[i]]
  })
  console.log(nightsDbg);
  */
  return nights;
}

function formatXAxis(tickItem) {
  return format(new Date(tickItem), dateFormat);
}

export default function Chart(props) {
  const theme = useTheme();
  const classes = useStyles(props);
  const { data, isLoading, isSuccess } = props.forecast;
  if ( ! isSuccess ) return null;
  var hourly_data = Object.values(data.hourly.data);
  var daily_data = Object.values(data.daily.data);
  if (props.day !== undefined) {
    hourly_data = hourly_data.filter((point) => {
      const time = new Date(point.time * 1000);
      if (isSameDay(props.day, time)) {
        return true;
      }
      // We want the charts to show midnight to midnight. Without the block
      // below, they'll stop at 23:00.
      if (
        isSameDay(time, startOfDay(addDays(props.day, 1))) &&
        format(time, "HH:mm:ss") === "00:00:00"
      ) {
        return true;
      }
      return false;
    });
    daily_data = daily_data.filter((point) => {
      const time = new Date(point.time * 1000);
      return isSameDay(props.day, time);
    });
  }
  const chart_data = hourly_data.map((hour) => {
    return formatHour(hour);
  });
  if ( ! chart_data.length ) return null;
  const nights = snapSunEvents(hourly_data, daily_data);
  const ticks = chart_data
    .map((h) => h.Time)
    .filter((t) => t % (3600 * 6) === 0);
  return (
    <React.Fragment>
      <div className={classes.chartContainer}>
        <ResponsiveContainer>
          <ComposedChart data={chart_data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              ticks={ticks}
              minTickGap={0}
              dataKey="Time"
              domain={[
                chart_data[0].Time,
                chart_data[chart_data.length - 1].Time,
              ]}
              tickFormatter={formatXAxis}
              stroke={theme.palette.text.primary}
            />
            <YAxis width={30} stroke={theme.palette.text.primary} />
            <Tooltip
              labelFormatter={formatXAxis}
              className={classes.tooltipContent}
              contentStyle={{
                padding: theme.spacing(0.5),
                backgroundColor: theme.palette.background.default + "77",
                fontSize: "0.9em",
              }}
              itemStyle={{
                padding: "0px",
              }}
            />
            <Line
              type="monotone"
              dataKey="Temperature"
              stroke="#FF7140"
              activeDot={{ r: 8 }}
              dot={false}
              unit="°"
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="Humidity"
              stroke="#82ca9d"
              dot={false}
              unit="%"
              isAnimationActive={false}
            />
            <Area
              type="monotone"
              dataKey="Precip. Chance"
              stroke="#8884d8"
              fill="#8884d8"
              fillOpacity={0.5}
              dot={false}
              unit="%"
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="Wind Speed"
              stroke="#aaaaaa"
              dot={false}
              unit="mph"
              isAnimationActive={false}
            />
            {nights.map((night) => {
              return (
                <ReferenceArea
                  x1={night[0]}
                  x2={night[1]}
                  key={night[0]}
                  fill={theme.palette.grey[900]}
                />
              );
            })}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </React.Fragment>
  );
}
