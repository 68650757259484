import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import AddLocationDialog from "../AddLocationDialog";

export default function LocationEditButton(props) {
  const [open, setOpen] = useState(false);
  if (!props.editing || props.dragging) return null;
  const handleClose = (action) => {
    setOpen(false);
    if (action === undefined) return;
    if (action.payload === undefined) return;
    if (action.type === "click") return;
    props.dispatch(action);
  };
  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        style={{ padding: 0 }}
        size="large"
      >
        <EditIcon />
      </IconButton>
      <AddLocationDialog
        open={open}
        handleClose={handleClose}
        defaultName={props.defaultName}
        defaultGeo={props.defaultGeo}
        mode="edit"
      />
    </>
  );
}
