import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  tempRange: {
    width: "5rem",
    display: "inline-flex",
    justifyContent: "flex-end",
  },
  tempValue: {
    width: "2rem",
  },
  dimText: {
    color: theme.palette.text.hint,
  },
}));

export default function TempRange(props) {
  const classes = useStyles();
  const { high, low } = props;
  const usable = (i) => !isNaN(i) && Number(i) === i;
  return (
    <div className={classes.tempRange}>
      <Typography className={classes.tempValue}>
        {usable(high) ? Math.round(high) + "°" : null}
      </Typography>
      <Typography className={classes.dimText}>
        {usable(low) && usable(high) ? " | " : null}
      </Typography>
      <Typography className={`${classes.tempValue} ${classes.dimText}`}>
        {usable(low) ? Math.round(low) + "°" : null}
      </Typography>
    </div>
  );
}
