import { useQueryClient, useIsFetching } from "@tanstack/react-query";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";

export default function FetchError(props) {
  const queryClient = useQueryClient();
  const isFetching = useIsFetching();
  const retryQueries = () => {
    queryClient.invalidateQueries({ stale: true });
  };
  let msg = "";
  let response = null;
  const errors = props.error.filter((item) => item);
  if (errors.length) {
    const error = errors[0];
    response = error.response;
    if (error.message !== undefined) {
      msg += ` ${error.message}`;
    } else if (response !== undefined) {
      if (response.statusText !== undefined) msg += ` ${response.statusText}`;
    }
  }
  return (
    <div
      style={{ display: "flex", justifyContent: "center", paddingTop: "40px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography color="textPrimary">
          Failed to fetch forecast from NOAA!
        </Typography>
        {msg ? <Typography>Error was: {msg}</Typography> : null}
        <Button
          onClick={retryQueries}
          variant="contained"
          color="primary"
          style={{ width: "fit-content", marginTop: "2 rem" }}
          disabled={isFetching > 0}
        >
          {isFetching ? (
            <CircularProgress style={{ width: 24, height: 24 }} />
          ) : (
            <RefreshIcon />
          )}
          &nbsp;Retry
        </Button>
      </div>
    </div>
  );
}
