import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function SourceSwitcher (props) {
  const doSwitch = () => {
    switch ( props.source ) {
      case "noaa":
        props.setSource("openweathermap");
        break;
      case "openweathermap":
        props.setSource("pirateweather");
        break;
      case "pirateweather":
        props.setSource("noaa");
        break;
      default:
        console.log(`Source is ${props.source}??`);
    }
  };
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
    }}>
    <Button
      onClick={doSwitch}
      sx={{
        alignSelf: "end",
        padding: "0px",
      }}
    >
      <Typography
        variant="caption"
        color="textSecondary"
      >
        source: {props.source}
      </Typography>
    </Button>
    </Box>
  );
};
