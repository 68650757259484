import { Navigate, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import useGeolocation from "../../lib/geolocate";


const errors = {
  1: "Permission denied",
  2: "Position unavailable",
  3: "Timeout",
}

function NoLocation(props) {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  let msg= "";
  if ( !props.error ) msg = "Locating...";
  else msg = props.error.message;
  // else msg = errors[props.error.code];
  return (
    <div>
      <Typography>{msg}</Typography>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "3rem" }}
        onClick={goBack}
      >
        <ArrowBackIcon />
        &nbsp;Go Back
      </Button>
    </div>
  );
}

export default function CurrentLocation() {
  const {coords, timestamp, error} = useGeolocation({
    enableHighAccuracy: true,
    maximumAge: 30000,
    timeout: 10000,
  });
  if ( !coords || !coords.latitude && coords.latitude != 0 ) {
    return <NoLocation error={error} />;
  }
  let { latitude, longitude, altitude } = coords;
  const lat = latitude.toFixed(4);
  const lng = longitude.toFixed(4);
  let dest = `/forecast/${lat},${lng}`;
  if ( !! coords.altitude ) {
    const alt = altitude.toFixed(2);
    dest += `?altitude=${alt}`;
  }
  return (
    <Navigate
      replace
      to={dest} />
  );
}
