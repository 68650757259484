import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import * as WeatherIcon from "react-icons/wi";
import { format } from "date-fns-tz";

import { getCardinalDirection } from "../../lib/noaa";
import { parseIconString } from "../../lib/pirateweather";
import Chart from "../Chart";
import TempRange from "../TempRange";
import FetchError from "../FetchError";

import "../../index.css";
import "./index.css";

// const timeFormat = "HH:mm (z)";
const dateFormat = "EE do";
// const dateTimeFormat = dateFormat + ", " + timeFormat;

function _format(date, _formatString, opts) {
  if (isNaN(date)) {
    return "";
  }
  return format(date, _formatString, opts);
}

const useStyles = makeStyles((theme) => ({
  dayPaper: {
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: `${theme.spacing(1.5)} 0`,
    },
    margin: `${theme.spacing(1)} ${theme.spacing(-1)} !important`,
    borderRadius: "6px",
  },
  expansionPanelSummary: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  expansionPanelDetails: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  flex: {
    display: "flex",
  },
  flexColumn: {
    flexDirection: "column",
    flexGrow: 1,
  },
  flexRow: {
    flexDirection: "row",
    flexGrow: 1,
  },
  weatherIcon: {
    fontSize: "2.25rem",
    width: "3rem",
    height: "3rem",
  },
  smallIcon: {
    fontSize: theme.typography.htmlFontSize,
    verticalAlign: "text-top",
  },
  smallWIcon: {
    fontSize: "1.5rem",
    marginBottom: "-10px",
  },
  right: {
    position: "absolute",
    right: theme.spacing(1),
  },
}));

function ConditionDetail(props) {
  const classes = useStyles();
  return (
    <Typography className={`${classes.flex} ${classes.flexColumn}`}>
      <span className={classes.alignCenter}>{props.label}</span>
      <span className={classes.alignCenter}>{props.value}</span>
    </Typography>
  );
}

function Day(props) {
  const theme = useTheme();
  const data = props.forecast.data?.daily
    ? props.forecast?.data?.daily.data[props.day]
    : {};
  const classes = useStyles();
  let time;
  let header = "";
  if ( !!data.time ) {
    time = new Date(Number(data.time * 1000));
    header = format(time, dateFormat);
  }
  const sunrise = new Date(data?.sunriseTime * 1000);
  const sunset = new Date(data?.sunsetTime * 1000);
  let WIcon;
  if ( data.icon ) {
    let iconName = data.icon;
    if ( ! iconName.startsWith("Wi") ) iconName = parseIconString(iconName);
    WIcon = WeatherIcon[iconName];
  } else {
    WIcon = Box;
  }
  const WIconRain = WeatherIcon["WiRain"];
  const WIconWind = WeatherIcon["WiStrongWind"];
  // console.log(theme)
  return (
    <details class="day-paper">
      <summary>
        <div style={{width: "5rem"}}>
            <WIcon className={classes.weatherIcon} />
            &nbsp;
        </div>
        <div style={{flex: "10 0"}}>
          <Typography>{header}</Typography>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.secondary }}
          >
            {data?.summary}
          </Typography>
        </div>
        <TempRange high={data?.temperatureHigh} low={data?.temperatureLow} />
      </summary>
      <div className={classes.expansionPanelDetails}>
        <Box
          className={`${classes.flex} ${classes.flexRow}`}
          style={{ paddingBottom: "15px" }}
        >
          <Typography variant="body2">{data?.detail}</Typography>
        </Box>
        <Box className={`${classes.flex} ${classes.flexRow}`}>
          <ConditionDetail
            label={<WIconRain className={classes.smallWIcon} />}
            value={
              Math.round((data?.precipProbability || 0) * 100) +
              "% " +
              (data?.precipAccumulation || 0).toFixed(2) +
              '"'
            }
          />
          <ConditionDetail
            label={<WIconWind className={classes.smallWIcon} />}
            value={`${getCardinalDirection(data?.windDirection || 0)} ${
              data?.windSpeed
            } mph`}
          />
          <ConditionDetail
            label="Humidity"
            value={Math.round((data?.humidity || 0) * 100) + "%"}
          />
          <ConditionDetail
            label={<Brightness7Icon className={classes.smallIcon} />}
            value={_format(sunrise, "HH:mm")}
          />
          <ConditionDetail
            label={<Brightness3Icon className={classes.smallIcon} />}
            value={_format(sunset, "HH:mm")}
          />
        </Box>
        <Box>
          <Chart day={time} height={200} forecast={props.forecast} />
        </Box>
      </div>
    </details>
  );
}

export default function DailyView(props) {
  const { data, isLoading, isInitialLoading } = props.forecast;
  let days = [];
  if (isLoading || isInitialLoading) {
    days = [...Array(7).keys()]
  } else if (data.daily !== undefined) {
    days = Object.keys(data.daily.data);
  }
  if (props.forecast.isError)
    return <FetchError error={props.forecast.error} />;
  return (
    <React.Fragment>
      {days.map((day) => (
        <Day forecast={props.forecast} day={day} key={day} />
      ))}
    </React.Fragment>
  );
}
