import { createContext, useReducer } from "react";

import { useReverseGeocode } from "./geocode";


const LocationContext = createContext({});

const locations = [
  {
    name: "Boulder, CO",
    geo: "40.015,-105.2705",
  },
  {
    name: "Lincoln Lake, CO",
    geo: "39.6182,-105.6036",
  },
  {
    name: "Lower Chaos Canyon, CO",
    geo: "40.3058,-105.6606",
  },
  {
    name: "Guanella Pass, CO",
    geo: "39.6395,-105.7078",
  },
  {
    name: "Crested Butte, CO",
    geo: "38.8686,-106.9802",
  },
];

function initLocations(defaultValue) {
  const stored = localStorage.getItem("locations");
  let value = [];
  if (`${stored}` !== "undefined") {
    try {
      value = JSON.parse(stored);
    } catch (error) {
      console.error(error);
    }
  }
  // Filter out any leftover "Current Location" items
  if (value?.length) return value.filter((i) => i.geo !== "__locate__");
  else return [...defaultValue];
}

function reducer(state, action) {
  if (state === undefined) {
    return state;
  }
  const oldStateString = JSON.stringify(state);
  let newState;
  switch (action.type) {
    case "add":
      const index = state.findIndex((item) => {
        if (item.geo === action.payload.geo) return true;
        if (item.name === action.payload.name) return true;
        return false;
      });
      if (index !== -1) {
        newState = [...state];
        newState[index].name = action.payload.name;
        newState[index].geo = action.payload.geo.replaceAll(" ", "");
        const altitude = action.payload.altitude;
        if ( ! isNaN(altitude) ) newState[index].altitude = altitude;
        const source = action.payload.source;
        if ( !! source ) newState[index].source = source;
      } else {
        newState = [...state, { ...action.payload }];
      }
      break;
    case "remove":
      newState = state.filter((item) => item.geo !== action.payload.geo);
      break;
    case "move":
      if (action.payload.source === action.payload.dest) {
        newState = state;
        break;
      }
      newState = Array.from(state);
      const item = newState.splice(action.payload.source, 1)[0];
      newState.splice(action.payload.dest, 0, item);
      break;
    case "replaceAll":
      const locations = action.payload.locations;
      if (validateLocations(locations)) newState = locations;
      break;
    default:
      console.log("unhandled action", action);
  }
  const newStateString = JSON.stringify(newState);
  if (newState !== undefined && oldStateString != newStateString) {
    localStorage.setItem("locations", newStateString);
  }
  return newState;
}

function validateGeo(geo) {
  if (!/-?\d+,-?\d+/.test(geo)) return false;
  const [lat, lng, alt] = geo.split(",");
  if ( alt !== undefined && isNaN(alt) ) return false;
  if (lat > -90 && lat < 90 && lng > -180 && lng < 180) {
    return true;
  }
  return false;
}

function validateName(name) {
  if (!name) return false;
  if (!typeof name === "string") return false;
  if (name.length > 100) return false;
  return true;
}

function validateLocations(locations) {
  if (!Array.isArray(locations)) return false;
  if (!locations.length) return false;
  return locations.every(
    (loc) => validateName(loc.name) && validateGeo(loc.geo)
  );
}

function useLocations() {
  const [state, dispatch] = useReducer(reducer, locations, initLocations);
  const findEntry = (geo) => {
    const matches = state.filter((item) => item.geo === geo);
    if ( matches.length ) return matches[0];
  };
  const getName = (geo) => {
    return findEntry(geo)?.name;
  };
  const getAltitude = (geo) => {
    return findEntry(geo)?.altitude;
  };
  const getSource = (geo) => {
    return findEntry(geo)?.source;
  };
  const useLocationName = (geo) => {
    let [latitude, longitude] = [null, null];
    const storedName = getName(geo);
    if ( !storedName && geo !== undefined ) [latitude, longitude] = geo.split(",");
    const reverseGeocode = useReverseGeocode({ latitude, longitude});
    if ( storedName ) return storedName;
    let locationName = "";
    if ( geo === "__locate__" ) {
      return "Current Location";
    }
    if ( !storedName && reverseGeocode.isSuccess && !! reverseGeocode.data?.address ) {
      const address = reverseGeocode.data.address;
      const municipality = address.city || address.town || address.county || address.state;
      const region = address.state || address.country;
      locationName = `${municipality}, ${region}`;
    } else if ( !locationName ) {
      locationName = null;
    }
    return locationName;
  };
  return {
    state,
    dispatch,
    findEntry,
    getName,
    getAltitude,
    getSource,
    useLocationName,
    validateName,
    validateGeo,
    validateLocations,
  };
}

export { LocationContext, useLocations };
