import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "@fontsource/roboto";

import "./index.css";
import App from "./App";
import ServiceWorkerHelper from "./components/ServiceWorkerHelper";
import useDarkMode from "./lib/useDarkMode";
import { LocationContext, useLocations } from "./lib/locations";
import LocationDetail from "./components/LocationDetail";
import CurrentLocation from "./components/CurrentLocation";
import LocationList from "./components/LocationList";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: "offlineFirst",
      retry: (failCount, error) => {
        if (error.response?.status === 404) return false;
        if (error.response?.status === 503) return false;
        if (failCount < 5) return true;
        return false;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      networkMode: "offlineFirst",
      queryFn: async ({ queryKey }) => {
        return axios
          .get(queryKey[2].url, { timeout: 5000 })
          .then((resp) => resp.data);
      },
    },
  },
});

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: "index.html",
        element: (<a href="/">go back</a>)
      },
      {
        path: "/",
        element: (<LocationList />)
      },
      {
        path: "add",
        children: [
          {
            path: ":geo",
            element: (<LocationDetail />)
          },
        ],
      },
      {
        path: "forecast",
        children: [
          {
            path: "__locate__",
            element: (<CurrentLocation />)
          },
          {
            path: ":geo",
            element: (<LocationDetail />)
          },
        ],
      },
    ],
  },
]);

function Root() {
  const [darkMode] = useDarkMode();
  const locations = useLocations();
  const locContextValue = React.useMemo(() => locations, [locations]);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const paletteType = darkMode ? "dark" : "light";
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: { mode: paletteType },
        components: {
          MuiUseMediaQuery: {
            defaultProps: {
              noSsr: true,
            },
          },
        },
      }),
    [paletteType]
  );
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocationContext.Provider value={locContextValue}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            <ServiceWorkerHelper />
            <App
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
            />
          </QueryClientProvider>
        </LocationContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById("root")
);
