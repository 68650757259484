import { useQuery } from "@tanstack/react-query";
import { utcToZonedTime } from "date-fns-tz";

const apiKey = import.meta.env.VITE_OPENWEATHERMAP_KEY;
const baseUrl = `https://api.openweathermap.org/data/3.0/onecall?appid=${apiKey}&units=imperial`;

const iconMap = {
  "01": ["DaySunny", "NightClear"],
  "02": ["Cloud", "NightAltPartlyCloudy"],
  "03": ["Cloudy", "NightAltCloudy"],
  "04": ["Cloudy", "NightAltCloudy"],
  "09": ["DayRain", "NightAltRain"],
  "10": ["DayRain", "NightAltRain"],
  "11": ["Thunderstorm", "NightAltThunderstorm"],
  "13": ["DaySnow", "NightSnow"],
  "50": ["DayFog", "NightFog"],
};

const fieldMap = {
  "dt": {name: "time"},
  "dew_point": {name: "dewPoint"},
  "feels_like": {name: "apparentTemperature"},
  "humidity": {name: "humidity", scale: 1/100},
  "pop": {name: "precipProbability"},
  "rain": {
    name: "precipAccumulation",
    fn: (n) => n / 25.4,
  },
  "snow": {
    name: "precipAccumulation",
    fn: (n) => n / 25.4,
  },
  "summary": {name: "detail"},
  "sunrise": {name: "sunriseTime"},
  "sunset": {name: "sunsetTime"},
  "temp": {
    name: "temperature",
    subKeys: {
      "max": {name: "temperatureHigh"},
      "min": {name: "temperatureLow"},
    },
  },
  "uvi": {name: "uvIndex"},
  "weather": {
    subKeys: {
      "icon": {
        name: "icon",
        fn: (iconName) => {
          const icons = iconMap[iconName.slice(0, 2)];
          const index = iconName.slice(-1) === "d" ? 0 : 1;
          return "Wi" + icons[index];
        },
      },
      "main": {name: "summary"},
    },
  },
  "wind_deg": {name: "windBearing"},
  "wind_speed": {name: "windSpeed"},
  "wind_gust": {name: "windGust"},
};

function translateOne(map, value, timeZone) {
  if ( !!map.scale ) value *= map.scale;
  const name = map.name;
  if ( !!name && name.toLowerCase().includes("time") ) {
    const date = utcToZonedTime(value * 1000, timeZone);
    value = Number(date) / 1000;
  }
  if ( !!map.fn ) value = map.fn(value);
  return [name, value]
}

function translateFull(orig, timeZone) {
  const result = {};
  Object.entries(orig).forEach(entry => {
    const [k, v] = entry;
    let [name, value] = [k, v];
    const map = fieldMap[k];
    if ( !!map ) {
      if ( value.constructor === Array ) value = value[0];
      if ( typeof(value) !== "object" ) {
        [name, value] = translateOne(map, value, timeZone);
        result[name] = value;
      } else if ( map.subKeys ) {
        Object.entries(map.subKeys).forEach(entry => {
          const [subKey, subMap] = entry;
          const [subName, subValue] = translateOne(subMap, value[subKey], timeZone);
          result[subName] = subValue;
        });
      }
    } else if ( typeof(value) !== "object" ) {
      result[name] = value;
    }
  });
  return result;
}


function useForecast({ latLong, enabled }) {
  const [lat, lng] = latLong.split(",");
  const url = `${baseUrl}&lat=${lat}&lon=${lng}&exclude=minutely,alerts`;
  const resp = useQuery({
    queryKey: ["openweathermap", "forecast", { url }],
    staleTime: 1000 * 60 * 60 * 24,
    enabled,
    select: (orig) => {
      const tz = orig.timezone;
      const daily = orig.daily.map(item => translateFull(item, tz));
      const hourly = orig.hourly.map(item => translateFull(item, tz));
      const result =  {
        currently: translateFull(orig.current, tz),
        daily: {data: daily},
        hourly: {data: hourly},
      };
      return result;
    },
  });
  return resp;
}

export { useForecast }
