import { useRegisterSW } from 'virtual:pwa-register/react'
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { pwaInfo } from 'virtual:pwa-info'

export default function ServiceWorkerHelper() {
  // replaced dynamically
  const buildDate = '__DATE__'
  // replaced dyanmicaly
  const reloadSW = '__RELOAD_SW__'
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      console.log(`Service Worker at: ${swUrl}`)
      if (reloadSW === 'true') {
        r && setInterval(() => {
          console.log('Checking for sw update')
          r.update()
        }, 20000 /* 20s for testing purposes */)
      }
      else {
        console.log('SW Registered', r);
      }
    },
    onRegisterError(error) {
      console.error('SW registration error', error)
    },
  })
  console.log(
    "ServiceWorkerHelper pwaInfo", pwaInfo, 
    "offlineReady", offlineReady,
    "needRefresh", needRefresh
  ); 
  const doUpdate = () => {
    updateServiceWorker(true);
    location.reload();
  }

  return (
    <Snackbar
      open={offlineReady || needRefresh}
      onClick={() => updateServiceWorker(true)}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{ "* .MuiAlert-message": { fontSize: "1.2rem" } }}
    >
      <Alert
        severity="info"
        action={
          <Button color="inherit" size="small" onClick={doUpdate}>
            Reload
          </Button>
        }
      >
        Got&nbsp;a&nbsp;new version&nbsp;for&nbsp;ya&nbsp;🥳
      </Alert>
    </Snackbar>
  );
}
