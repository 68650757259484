import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

function useDarkMode() {
  const systemDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });
  const [state, setState] = useState({ system: systemDarkMode });

  function setDarkMode(value) {
    const newState = { ...state, user: value };
    if (value !== state.user) {
      setState(newState);
    }
    setState(newState);
  }
  const darkMode = state.user === undefined ? systemDarkMode : state.user;
  return [darkMode, setDarkMode];
}

export default useDarkMode;
