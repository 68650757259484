import React from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import { ArrowBack } from "@mui/icons-material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AddLocationDialog from "../AddLocationDialog";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { LocationContext } from "../../lib/locations";
import RefreshWithProgress from "../RefreshWithProgress";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  endIcons: {
    position: "absolute",
    right: "0%",
  },
  toolbarIcon: {
    ...theme.mixins.toolbar,
    position: "sticky",
    top: "0",
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
  },
}));

function BackButton(props) {
  const navigate = useNavigate();
  if ( ! props.enabled ) return null;
  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="open drawer"
      onClick={() => {
        localStorage.setItem("lastGeo", "");
        navigate("/");
      }}
      size="large"
    >
      <ArrowBack />
    </IconButton>
  )
}

export default function AppBar() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { geo } = useParams();
  const { useLocationName, getName, dispatch } = React.useContext(LocationContext);
  const locationName = useLocationName(geo);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleClose = (action) => {
    setOpen(false);
    if (action === undefined) return;
    if (action.payload === undefined) return;
    if (action.type === "click") return;
    const geo = action.payload.geo;
    navigate(`/forecast/${geo}`);
    dispatch(action);
  };
  return (
    <MuiAppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <BackButton enabled={!!geo} />
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {locationName || "Bluebird Weather"}
        </Typography>
        <div className={classes.endIcons}>
          <AddLocationDialog
            open={open}
            defaultName={locationName}
            handleClose={handleClose}
          />
          { !geo || !! getName(geo) ?
            null :
            <IconButton color="inherit" aria-label="add current location" onClick={() => setOpen(true)}>
              <Link
                to={`/add/${geo}`}
                style={{
                  height: "1em",
                  color: theme.palette.text.primary,
                  textDecoration: "none",
                }}
              >
                <AddLocationIcon />
              </Link>
            </IconButton>
          }
          <IconButton color="inherit" aria-label="current location">
            <Link
              to="/forecast/__locate__"
              style={{
                height: "1em",
                color: theme.palette.text.primary,
                textDecoration: "none",
              }}
            >
              <MyLocationIcon />
            </Link>
          </IconButton>
          <IconButton color="inherit" aria-label="refresh">
            <RefreshWithProgress />
          </IconButton>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
}
