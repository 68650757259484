import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { LocationContext } from "../../lib/locations";
import GeocodeAutocomplete from "../GeocodeAutocomplete";

export default function AddLocationDialog(props) {
  const { geo: geoParam } = useParams();
  const { validateName, validateGeo, useLocationName } = useContext(LocationContext);
  const locationName = useLocationName(geoParam);
  const defaultName = props.defaultName || locationName;
  const defaultGeo = geoParam || props.defaultGeo;
  const [name, setName] = React.useState(defaultName);
  const [nameValid, setNameValid] = React.useState(validateName(defaultName));
  const nameChanged = (name) => {
    const valid = validateName(name);
    setNameValid(valid);
    setName(name);
  };
  const [geo, setGeo] = React.useState(defaultGeo ? defaultGeo : "");
  const [geoValid, setGeoValid] = React.useState(false);
  const geoChanged = (geo) => {
    geo = geo.replaceAll(" ", "");
    const valid = validateGeo(geo);
    setGeoValid(valid);
    setGeo(geo);
  };

  React.useEffect(() => {
    setNameValid(validateName(name));
    setGeoValid(validateGeo(geo));
  }, [props.open, name, geo, validateGeo, validateName]);
  React.useEffect(() => {
    setName(props.defaultName)
  }, [props.defaultName])

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Location</DialogTitle>
        <DialogContent>
          {props.mode === "add" ? (
            <>
              <GeocodeAutocomplete setName={setName} setGeo={setGeo} />
              <DialogContentText>
                Or enter name and coordinates
              </DialogContentText>
            </>
          ) : null}
          <TextField
            error={!nameValid}
            value={name}
            margin="dense"
            id="name"
            label="Name"
            onChange={(evt) => {
              nameChanged(evt.target.value);
            }}
            fullWidth
          />
          <TextField
            error={!geoValid}
            value={geo}
            margin="dense"
            id="geo"
            helperText={geoValid ? "" : "Invalid coordinates!"}
            label="Coordinates"
            onChange={(evt) => {
              geoChanged(evt.target.value);
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          {props.defaultName ? (
            <Button
              onClick={() => {
                props.handleClose({
                  type: "remove",
                  payload: { name: name, geo: geo },
                });
              }}
            >
              Delete
            </Button>
          ) : null}
          <Button
            onClick={() => {
              props.handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!(nameValid && geoValid)}
            onClick={() => {
              props.handleClose({ type: "add", payload: { name, geo } });
            }}
          >
            {defaultGeo ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
