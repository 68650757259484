import React from "react";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as WeatherIcon from "react-icons/wi";
import { format } from "date-fns";

import { getCardinalDirection } from "../../lib/noaa";
import { parseIconString } from "../../lib/pirateweather";

const useStyles = makeStyles((theme) => ({
  observations: {
    display: "flex",
    height: "6rem",
  },
  observationContainer: {
    marginLeft: "auto",
    alignItems: "center",
    textAlign: "center",
    width: "fit-content",
  },
  flexColumn: {
    flexDirection: "column",
    flexGrow: 1,
  },
  textAlignEnd: {
    textAlign: "end",
  },
  dimText: {
    color: theme.palette.text.hint,
  },
  weatherIcon: {
    fontSize: "3rem",
  },
}));

function CurrentTemp(props) {
  if (props.temp === undefined || props.temp === null) {
    return null;
  }
  return <React.Fragment>{Math.round(props.temp)}°</React.Fragment>;
}

function WindInfo(props) {
  if (props.direction === null || props.speed === null) return null;
  return (
    <Typography>
      {getCardinalDirection(props.direction)}&nbsp;
      {Math.round(props.speed)} mph
    </Typography>
  );
}

function Ozone(props) {
  if ( props.ozone == null ) return null;
  return (
    <Typography>{Math.round(props.ozone)} o³</Typography>
  )
}

function StationInfo({station}) {
  if ( ! station ) return null;
  return (
    <Typography variant="caption" color="textSecondary">
      &nbsp;from {station.code} ({station.distance}mi away)
    </Typography>
  )
}

export default function CurrentObservations({currently, station}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  if ( currently === undefined ) return <Box style={{ height: "6rem" }} />;
  let WIcon = Box;
  if ( currently.icon ) {
    let iconName = currently.icon;
    if ( ! iconName.startsWith("Wi") ) iconName = parseIconString(iconName);
    WIcon = WeatherIcon[iconName];
  }
  return (
    <div className={classes.observations}>
      <div>
        <Typography variant="h4">
          <WIcon className={classes.weatherIcon} />
          &nbsp;
        </Typography>
      </div>
      <div className={classes.observationContainer}>
        <Typography variant="h5">
          <CurrentTemp temp={currently.temperature} />
        </Typography>
        <WindInfo
          direction={currently.windDirection}
          speed={currently.windSpeed}
        />
        <Typography>
          {Math.round(currently.humidity * 100)}% humidity
        </Typography>
        <Ozone ozone={currently.ozone} />
      </div>
      <div className={`${classes.flexColumn} ${classes.textAlignEnd}`}>
        <Typography variant="body2">{currently.summary}</Typography>
        <Typography variant="caption" color="textSecondary">
          Updated {format(new Date(currently.time * 1000), "EE do HH:mm")}
        </Typography>
        <div></div>
        <StationInfo station={station} />
      </div>
    </div>
  );
}
