import React from "react";

export default function useGeolocation (options) {
  const options_ = {timeout: 30 * 1000, enableHighAccuracy: true, ...options};
  const [state, setState] = React.useState({coords: null, timestamp: null, error: null});
  const error = React.useCallback((error_) => {
    state.error = error_;
    setState(state);
  }, []);
  const success = React.useCallback((position) => {
    const newState = {
      coords: position.coords,
      timestamp: position.timestamp,
      error: null,
    };
    setState(newState);
  }, []);
  React.useEffect(() => {
    if (!navigator.geolocation) {
      result.error = {
        code: 2,
        message: "Geolocation is not supported by your browser",
      }
    } else {
      navigator.geolocation.getCurrentPosition(success, error, options_);
    }
  }, []);
  return state;
}
