import { useQuery } from "@tanstack/react-query";
import isBefore from "date-fns/isBefore";

const apiKey = import.meta.env.VITE_PIRATE_WEATHER_KEY;
const baseUrl = `https://api.pirateweather.net/forecast/${apiKey}`


function useForecast({ latLong, enabled }) {
  const url = `${baseUrl}/forecast/${latLong}?extend=hourly&exclude=minutely,alerts`;
  const resp = useQuery({
    queryKey: ["pirate", "forecast", { url }],
    staleTime: 1000 * 60 * 60 * 24,
    enabled,
    select: (data) => {
      // drop days in the past
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      data.daily.data = data.daily.data.filter(
        day => ! isBefore(new Date(day.time * 1000), today));
      return data;
    },
  });
  return resp;
}

const iconMap = {
  "clear-day": "DaySunny", 
  "clear-night": "NightClear",
  "rain": "Rain",
  "snow": "Snow",
  "sleet": "Sleet",
  "wind": "StrongWind",
  "fog": "Fog",
  "cloudy": "Cloudy",
  "partly-cloudy-day": "DayCloudy",
  "partly-cloudy-night": "NightPartlyCloudy",
}

function parseIconString(iconString) {
  return "Wi" + iconMap[iconString];
}

export {
  useForecast,
  parseIconString,
}