import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import AddLocationDialog from "../AddLocationDialog";
import ImportLocationsDialog from "../ImportLocationsDialog";
import { Location } from "../Location";
import { LocationContext } from "../../lib/locations";

function SnackbarCloseButton(props) {
  return (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={props.handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
}

export default function LocationList() {
  const theme = useTheme();
  const { geo } = useParams();
  const [editing, setEditing] = useState(false);
  const { state, dispatch, getName } = useContext(LocationContext);
  const [open, setOpen] = useState(false);
  const [importOpen, setImportOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const navigate = useNavigate();
  const handleClose = (action) => {
    setOpen(false);
    if (action === undefined) return;
    if (action.payload === undefined) return;
    if (action.type === "click") return;
    const geo = action.payload.geo;
    if (getName(geo)) navigate(`/forecast/${geo}`);
    dispatch(action);
  };
  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (
      source?.droppableId !== "locationList" ||
      destination?.droppableId !== "locationList"
    )
      return;
    dispatch({
      type: "move",
      payload: { source: source.index, dest: destination.index },
    });
  };
  const exportLocation = () => {
    navigator.clipboard.writeText(JSON.stringify(state, null, 2));
    setToastOpen(true);
  };
  const handleImportClose = (action) => {
    setImportOpen(false);
    if (action === undefined) return;
    if (action.payload === undefined) return;
    if (action.type === "click") return;
    dispatch(action);
  };
  const closeSnackbar = () => {
    setToastOpen(false);
  };
  return (
    <Box sx={{ height: "100%", width: "inherit" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="locationList">
          {(provided, snapshot) => (
            <List
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{ paddingBottom: "5rem" }}
            >
              {state.map((loc, index) => (
                <Location
                  key={loc.geo}
                  index={index}
                  geo={loc.geo}
                  name={loc.name}
                  selected={geo == loc.geo}
                  editing={editing}
                  dragging={
                    snapshot.isDraggingOver || snapshot.draggingFromThisWith
                  }
                  dispatch={dispatch}
                />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "inherit",
          paddingRight: theme.spacing(1),
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          background: theme.palette.background.default,
        }}
      >
        <IconButton size="large" onClick={exportLocation}>
          <FileDownloadIcon />
        </IconButton>
        <Snackbar
          message="Locations copied to clipboard!"
          open={toastOpen}
          autoHideDuration={5000}
          onClose={closeSnackbar}
          action={<SnackbarCloseButton handleClose={closeSnackbar} />}
        />
        <IconButton
          size="large"
          onClick={() => {
            setImportOpen(true);
          }}
        >
          <FileUploadIcon />
        </IconButton>
        <IconButton onClick={() => setOpen(true)} size="large">
          <AddLocationIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setEditing(!editing);
          }}
          size="large"
        >
          {editing ? <DoneIcon /> : <EditIcon />}
        </IconButton>
        <AddLocationDialog open={open} handleClose={handleClose} mode="add" />
        <ImportLocationsDialog
          open={importOpen}
          handleClose={handleImportClose}
        />
      </Box>
    </Box>
  );
}
