import Typography from "@mui/material/Typography";

export default function ErrorFallback(props) {
  // console.error();
  return (
    <div>
      <Typography color="textPrimary">Aw shit, we crashed.</Typography>
      <Typography style={{ textAlign: "initial" }}>
        {props.error?.message || ""}
      </Typography>
    </div>
  );
}
